.login_btn {
    /* background: linear-gradient(132deg, #153747, #005a84) !important;  */
    color: #ffffff;
    font-size: 15px;
    padding: 12px 20px !important;
    width: 100%;
}
.login_card {
    border-top: 2px solid #795548 !important;
    border-bottom: 2px solid #795548 !important;
    border-radius: 25px
}
